<div class="container-box">
  <div class="content-box">
    <div class="title-box">
      <div>Custom textract template for <i style="text-decoration: underline;">NT.Ngoc</i> customer</div>
    </div>

    <div class="detail-page">
      <div class="template-preview">
        <template-image-preview></template-image-preview>
      </div>

      <div class="form-custom">
        <div class="form-action">
          <div *ngIf="!isEditing" class="flex flex-space-between">
            <div class="top10"><h3>Template Informations</h3></div>
            <div><button class="btn-action" nz-button nzType="default" nzTheme="outline" (click)="onBtnEditTemplate()">Edit</button></div>
          </div>
          <div *ngIf="isEditing" class="flex flex-space-between">
            <div><button class="btn-action" nz-button nzType="default" nzTheme="outline" (click)="onBtnCancel()"  [disabled]="isLoading || isTesting">Cancel</button></div>
            <div class="flex gap10">
              <button class="btn-action" nz-button  nzType="default" nzTheme="outline" (click)="onBtnTesting()" [disabled]="isLoading">Test</button>
              <button class="btn-action" nz-button  nzType="primary" nzTheme="outline" (click)="onBtnSave()" [disabled]="isLoading || isTesting">Save</button>
            </div>
          </div>
        </div>
        <div class="separator h bottom20"></div>

        <div class="form-info">
          <form class="form-detail" [formGroup]="formInput" nz-form>
            <div>
              <div class="form-label-v2">Template file<span class="label-mark-required"></span></div>
              <button nz-button class="btn1" style="width: 100%;" (click)="inputFile.click()" [disabled]="isTesting || isLoading || !isEditing">
                <i *ngIf="hasAttachedFile('file')" nz-icon nzType="file-pdf" nzTheme="outline"></i>
                {{labelSelectFile}}
                <span *ngIf="!hasAttachedFile('file')" class="label-mark-required"></span>
              </button>
              <input #inputFile type="file" hidden accept=".pdf" (change)="onFileSelected('file', inputFile.files)">
            </div>

            <div nz-row class="top10">
              <div nz-col [nzFlex]="2">
                <div *ngFor="let key of ['eXSameLine', 'eXVertical', 'eYBetweenLines']">
                  <div class="flex top10">
                    <div class="form-label-v2">{{getLabel(key)}}:<span class="label-mark-required"></span></div>
                    <div style="padding-left: 20px;">
                      <input nz-input [formControlName]="key" style="max-width: 80px;">
                    </div>    
                  </div>
                </div>
              </div>
              <div nz-col [nzFlex]="2">
                <div *ngFor="let key of ['eYSameLine', 'eYSameValue', 'eYBetweenBlocks']">
                  <div class="flex top10">
                    <div class="form-label-v2">{{getLabel(key)}}:<span class="label-mark-required"></span></div>
                    <div style="padding-left: 20px;">
                      <input nz-input [formControlName]="key" style="max-width: 80px;">
                    </div>    
                  </div>
                </div>
              </div>
            </div>

            <div class="add-block-container top20">
              <div *ngFor="let type of blockTypeArr" class="top20">
                <div class="flex">
                  <div class="block-type">{{ templateBlockTypes[type].label }}</div>
                  <button nz-button class="btn-add-item" *ngIf="isEditing" [disabled]="isTesting || isLoading" (click)="addBlockItem(type)" 
                    nz-tooltip nzTooltipTitle="Add block">
                    <i nz-icon nzType="plus"></i>
                  </button>
                </div>
                <div class="list-block-item">
                  <nz-table #nzTable [nzData]="templateBlocks[type]" [nzNoResult]="tplNoData"
                    [nzFrontPagination]="false" nzBordered="true" nzSize="small"
                    [nzScroll]="{ x: '800px' }">
                    <ng-template #tplNoData>
                      <div *ngIf="!isLoading" class="nodata"></div>
                    </ng-template>
                    <thead>
                      <tr>
                        <th *ngFor="let header of keysOfBlock[type]" class="f12">{{ header }}</th>
                        <th class="f12">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of nzTable.data; let i = index">
                        <td>{{ item.title }}</td>
                        <td>{{ item.regconizeOutOfBlock }}</td>
                        <td>{{ item.xLeft }}</td>
                        <td>{{ item.xRight }}</td>
                        <td>{{ item.needIgnoreTitleOnProgressing }}</td>
                        <td *ngIf="type === 'form'">{{ item.isTableForm }}</td>
                        <td *ngIf="type === 'table'">{{ item.tableUniqColumn }}</td>
                        <td *ngIf="type === 'table'">{{ item.tableKeys }}</td>
                        <td *ngIf="type === 'implicit_line'">{{ item.implicitKeys }}</td>
                        <td>
                          <div class="flex">
                            <button *ngIf="!isLoading" nz-button nzType="text"
                              (click)="onBtnEditBlockItem(type, i)">
                              Edit
                            </button>
                            <button *ngIf="canDeleteItem(type, i)" nz-button nzType="text" class="btn-del-item"
                              nz-popconfirm [nzOkDanger]="true" nzOkText="Remove"
                              [nzPopconfirmTitle]="messageDeleteItem(type, i)"
                              (nzOnConfirm)="deleteItem(type, i)"
                              nzPopconfirmPlacement="top"
                              [nzIcon]="iconTpl"
                              [disabled]="isTesting || isLoading">
                              <span nz-icon nzType="delete" nzTheme="outline" class="danger"></span>
                              <ng-template #iconTpl><span nz-icon nzType="question-circle-o"></span></ng-template>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </nz-table>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>