<ng-template [nzModalTitle]>
  <div class="flex-space-between">
    <div>Edit Block Item</div>
    <div>
      <span nz-icon nzType="close" nzTheme="outline" class="clickable" (click)="closeDialog()"></span>
    </div>
  </div>
</ng-template>

<div>
  <form nz-form [formGroup]="formInput">
    <div *ngFor="let key of formInputKeys" class="top10">
      <div *ngIf="key !== 'type'" class="flex" style="align-items: center;">
        <div>{{getLabel(key)}}:</div>
        <div style="margin-left: 20px">
          <ng-container [ngSwitch]="key">
            <ng-container *ngSwitchCase="['needIgnoreTitleOnProgressing', 'isTableForm'].includes(key) ? key : 'booleanType'">
              <nz-select nzBackdrop="true" [formControlName]="key">
                <nz-option [nzValue]="true" [nzLabel]="'True'"></nz-option>
                <nz-option [nzValue]="false" [nzLabel]="'False'"></nz-option>
              </nz-select>            
            </ng-container>
            <input *ngSwitchDefault nz-input [formControlName]="key">
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</div>

<div form-footer class="no-border"
  [canClickOK]="needUpdate" 
  [canClickCancel]="true"
  [onProgress]="onProgress"
  [nzIconOK]="false" [nzIconCancel]="false" labelOK="Update" nzOkDanger="false" (onOK)="onBtnSave()"
  (onCancel)="closeDialog()">
</div>