import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomTextractTemplate } from './custom-template';

const textractTemplateRoutes: Routes = [
  {
    path: 'custom-textract-template', component: CustomTextractTemplate, data: { title: 'Custom Textract Template - WARP Admin' }
  }
];

@NgModule({
  imports: [RouterModule.forChild(textractTemplateRoutes)],
  exports: [RouterModule]
})
export class TextractTemplateRoutingModule {}