import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { BaseFormDialog1 } from "@dialogs/base-form-dlg1";

@Component({
  selector: "[edit-template-block-item]",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class EditTemplateBlockItem extends BaseFormDialog1 {
  @Input() onSave: (data) => any;
  @Input() onPropertiesChange: (data) => any;
  @Input() type = 'form' ||'table' ||'inline' ||'implicit_line';

  protected formGroupDeclaration: FormGroupDeclaration = {
    type: {label: 'Block type', required: true, type: 'string'},
    title: {label: 'Header', required: true, type: 'string'},
    regconizeOutOfBlock: {label: 'Flag out of block', required: true, type: 'string'},
    xLeft: {label: 'XLeft', required: true, type: 'number'},
    xRight: {label: 'XRight', required: true, type: 'number'},
    needIgnoreTitleOnProgressing: {label: 'Ignore Title', required: true, type: 'boolean'},
  }

  public templateBlockTypes = {
    form: {label: 'Form Block', value: 'FORM'},
    table: {label: 'Table Block', value: 'TABLE'},
    inline: {label: 'Inline Block', value: 'INLINE'},
    implicit_line: {label: 'Implicit Line Block', value: 'IMPLICIT_LINE'}
  };
  public blockTypeArr = ['form', 'table', 'inline', 'implicit_line'];

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.correctFormProperties();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  correctFormProperties() {
    switch (this.type) {
      case 'table': 
        this.addItemToFormGroup('tableUniqColumn', {label: 'Uniq Column', required: true, type: "string" }, this.model?.tableUniqColumn);
        this.addItemToFormGroup('tableKeys', {label: 'Table Keys', required: true, type: "string" }, this.model?.tableKeys);
        break;
      case 'form':
        this.addItemToFormGroup('isTableForm', {label: 'Is table-form', required: true, type: "boolean" }, this.model?.isTableForm);
        break;
      case 'implicit_line':
        this.addItemToFormGroup('implicitKeys', {label: 'Implicit Keys', required: true, type: "string" }, this.model?.implicitKeys);
        break;
      default:
        break;
    }
  }

  onBtnSave() {
    let data: any = {};
    data = this.getFormData_JSON(this.isCreateNew);
    this.onSave(data);
    this.closeDialog();
  }
}