import { BaseComponent } from "@abstract/BaseComponent";
import { Component, Input } from "@angular/core";
import { PanZoomConfig, PanZoomAPI } from 'ngx-panzoom';


export interface ExtractedLine {
  BlockType: string;
  Confidence: number;
  Geometry: {
    BoundingBox: {
      Height: number;
      Left: number;
      Top: number;
      Width: number;
    }
    Polygon: {
      X: number;
      Y: number;
    }[]
  }
  Id: string;
  Text: string;
  TextType: string;
}

export interface TextractImageTemplate {
  blob: Blob;
  name?: string;
  extractedData?: {
    lines?: ExtractedLine
  }
  templateBlocks?: any[];
}

@Component({
  selector: "template-image-preview",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class TemplateImagePreview extends BaseComponent {
  public panZoomConfig: PanZoomConfig = new PanZoomConfig({
    // scalePerZoomLevel: 1
    freeMouseWheelFactor: 0.01
  });
  @Input() selectedTemplate: TextractImageTemplate;
  private panZoomAPI: PanZoomAPI
  private observerResize: ResizeObserver;
  private isPanZoomReady: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.subscription.add(this.panZoomConfig.api.subscribe((api: PanZoomAPI) => {
      this.panZoomAPI = api;
      this.isPanZoomReady = true;
    }))
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    this.initResizeSensor()
  }

  initResizeSensor() {
    this.observerResize = new ResizeObserver(() => this.resetPanZoom())
    this.observerResize.observe(this.getContentDocument())
    this.observerResize.observe(document.querySelector(".template-preview"))
  }

  private getContentDocument() {
    return document.querySelector(".textract-template-panzoom .pan-zoom-frame .container-content");
  }

  resetPanZoom() {
    //check if panZoomAPI is available
    if (!this.isPanZoomReady) return;
    const contentWidth = this.getContentDocument()?.clientWidth;
    const contentHeight = this.getContentDocument()?.clientHeight;

    if (!contentWidth || !contentHeight) return;
    // this.panZoomAPI.detectContentDimensions()
    this.panZoomAPI.zoomToFit({ x: 0, y: 0, width: contentWidth, height: contentHeight });
    this.panZoomAPI?.resetView();
    this.panZoomAPI.centerX();

  }
}