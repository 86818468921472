import { BaseComponent } from "@abstract/BaseComponent";
import { Component } from "@angular/core";
import { BaseFormItem } from "@app/admin/base/form-item";
import { DialogService } from "@dialogs/dialog.service";
import { getDashboard } from "@services/index";
import { EditTemplateBlockItem } from "../components/form-textract-block-item.ts";

@Component({
  selector: "custom-textract-template",
  templateUrl: "./index.html",
  styleUrls: ["./index.scss"],
})
export class CustomTextractTemplate extends BaseFormItem {
  protected formGroupDeclaration: FormGroupDeclaration = {
    file: {label: 'Template file', required: true, type: 'uploadFile'},
    eXSameLine: {label: 'eX SameLine', required: true, type: 'number'},
    eYSameLine: {label: 'eY SameLine', required: true, type: 'number'},
    eXVertical: {label: 'eX Vertical', required: true, type: 'number'},
    eYSameValue: {label: 'eY SameValue', required: true, type: 'number'},
    eYBetweenLines: {label: 'eY BetweenLines', required: true, type: 'number'},
    eYBetweenBlocks: {label: 'eY BetweenBlocks', required: true, type: 'number'},
    clientId: {label: 'Customer', required: true},
  }

  public images: any = [];
  public client;
  public formInfoWidth: number = 40; //percent
  public isLoading: boolean = false;
  public isEditing: boolean = false;
  public isTesting: boolean = false;
  public templateBlockTypes = {
    form: {label: 'Form Blocks', value: 'FORM'},
    table: {label: 'Table Blocks', value: 'TABLE'},
    inline: {label: 'Inline Blocks', value: 'INLINE'},
    implicit_line: {label: 'Implicit Line Blocks', value: 'IMPLICIT_LINE'}
  };
  public blockTypeArr = ['form', 'table', 'inline', 'implicit_line'];
  public basicLabelKeysOfBlock = ['Header', 'Flag out of block', 'XLeft', 'XRight', "Ignore Header"];
  public keysOfBlock = {
    form: [...this.basicLabelKeysOfBlock, 'Is table-form'],
    table: [...this.basicLabelKeysOfBlock, 'Uniq Column', 'Table Keys'],
    inline: [...this.basicLabelKeysOfBlock],
    implicit_line: [...this.basicLabelKeysOfBlock, 'Implicit Keys']
  }
  public templateBlocks = {
    form: [],
    table: [],
    inline: [],
    implicit_line: []
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    setTimeout(() => getDashboard().sideBar.isSmallWidth = true, 1);
    this.model = this.defaultCustomValue
    this.createFormInput( this.model);
    this.setEnableFormGroup(false);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  get isReadonly() {
    return !this.isEditing;
  }

  get defaultCustomValue() {
    return {
      eXSameLine: 0.02,
      eYSameLine: 0.006,
      eXVertical: 0.005,
      eYSameValue: 0.009,
      eYBetweenLines: 0.02,
      eYBetweenBlocks: 0.03,
    }
  }

  public get labelSelectFile(): string {
    let key = 'file';
    return this.hasAttachedFile(key) ? this.getFileDesc(key) : 'Select file (pdf)'
  }

  onBtnEditTemplate() {
    this.isEditing = true;
    this.setEnableFormGroup(true);
  }

  onBtnTesting() {
    this.isTesting = true;
    setTimeout(() => {
      this.isTesting = false;
    }, 3000)
  }

  onBtnSave() {
    this.isEditing = false;
    this.isTesting = false;
  }

  onBtnCancel() {
    this.isEditing = false;
    this.isTesting = false;
    this.setEnableFormGroup(false);
  }

  addBlockItem(type) {
    if (!this.blockTypeArr.includes(type)) return;
    let newItem = this.initBlockData(type);
    this.templateBlocks[type] = [...this.templateBlocks[type], newItem];
  }

  initBlockData(type) {
    let data = {
      type: this.templateBlockTypes[type].value,
      title: "",
      regconizeOutOfBlock: "",
      xLeft: 0,
      xRight: 1,
      needIgnoreTitleOnProgressing: true
    }
    switch (type) {
      case 'table':
        data['tableUniqColumn'] = '';
        data['tableKeys'] = [];
        break;
      case 'form':
        data['isTableForm'] = false;
        break;
      case 'implicit_line':
        data['implicitKeys'] = [];
      default:
        break;
    }
    return data;
  }
  
  deleteItem(type, index) {
    this.templateBlocks[type].splice(index, 1);
    this.templateBlocks[type] = [...this.templateBlocks[type]];
  }

  canDeleteItem(type, i) {
    return this.isEditing;
  }
  
  messageDeleteItem(type, i) {
    return `Remove this block?`
  }

  onBtnEditBlockItem(type, index) {
    let item = this.templateBlocks[type][index];
    if (!item) return;
    
    DialogService.openFormDialog1(EditTemplateBlockItem, {
      nzComponentParams: {
        type: type,
        model: item,
        closeOnSuccess: true,
        onSave: data => {
          this.templateBlocks[type] = [...this.templateBlocks[type]]
          this.templateBlocks[type][index] = data;
        },
        onPropertiesChange: data => {}
      },
      nzWidth: '400px',
      nzClassName: "modal",
    });
  }

  onFileSelected(key, files) {
    super.onFileSelected(key, files);
    let file = files[0];
    // let images
  }

}