import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { DialogsModule } from '@dialogs/dialogs.module';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzPopconfirmModule } from "ng-zorro-antd/popconfirm";
import { NzModalModule } from 'ng-zorro-antd/modal';
import { DetailModule } from "@app/admin/base/detail.module";
import { NgxPanZoomModule } from 'ngx-panzoom';
import { TextractTemplateRoutingModule } from './textract-template.route';
import { CustomTextractTemplate } from './custom-template';
import { EditTemplateBlockItem } from './components/form-textract-block-item.ts';
import { TemplateImagePreview } from './components/template-preview';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ScrollingModule,
    ReactiveFormsModule,
    NzCheckboxModule,
    NzRadioModule,
    NzTagModule,
    NzSwitchModule,
    NzDropDownModule,
    NzTableModule,
    NzButtonModule,
    NzSelectModule,
    NzIconModule,
    NzGridModule,
    NzInputModule,
    NzFormModule,
    NzPaginationModule,
    NzAutocompleteModule,
    NzToolTipModule,
    NzTypographyModule,
    NzPopconfirmModule,
    NzModalModule,
    NgxPanZoomModule,
    DialogsModule,
    DetailModule,
    TextractTemplateRoutingModule,
  ],
  declarations:[
    CustomTextractTemplate,
    EditTemplateBlockItem,
    TemplateImagePreview,
  ],
  exports: [
    CustomTextractTemplate
  ]
})
export class TextractTemplateModule {}