<div class="container-box">
  <pan-zoom [config]="panZoomConfig" class="textract-template-panzoom">
    <div class="container-content">
      
    </div>
  </pan-zoom>

  <div class="no-data-box" *ngIf="!selectedTemplate">
    <img class="no-data" src="/assets/img/nodata.svg" alt="" />
    <div class="">Please upload file first.</div>
  </div>
</div>